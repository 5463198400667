import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import type { UntypedFormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import type { Driver } from 'src/app/admin/drivers/shared/driver';
import { DriverService, PersonDisplayNamePipe } from '../..';

@Component({
  selector: 'mtx-driver-autocomplete',
  templateUrl: './driver-autocomplete.component.html',
  styleUrls: ['./driver-autocomplete.component.scss'],
})
export class DriverAutocompleteComponent implements OnInit {

  @Input() public translateText?: string;

  @Input() public fgName?: string;

  @Output() public driverSelected = new EventEmitter<Driver>();

  @Input() private readonly driverObj?: Driver;


  public driverModel?: Driver;

  public constructor(@Inject(DriverService) private readonly driverService: DriverService,
    @Inject(ControlContainer) private readonly controlContainer: ControlContainer,
    @Inject(PersonDisplayNamePipe) private readonly displayNamePipe: PersonDisplayNamePipe) {}

  private get fg(): UntypedFormGroup | null {
    return this.fgName ? (this.controlContainer.control?.get(this.fgName) as UntypedFormGroup) : null;
  }

  public ngOnInit(): void {
    if (this.driverObj) {
      // if an existing driver object is passed in, use it to populate autocomplete
      this.driverModel = this.driverObj;
    }
  }

  public addDriver(): void {
    const driver = this.driverModel;
    if (driver?.id) {
      this.fg?.patchValue(driver);
      this.driverSelected.emit(driver);
    } else {
      this.fg?.patchValue({ id: null });
    }
  }

  public search = (text$: Observable<string>): Observable<Driver[]> => text$.pipe(debounceTime(200),
    distinctUntilChanged(),
    switchMap((term: string) => this.driverService.search({ term }).pipe(map((res) => {
      const self = (this.controlContainer.control?.value as Driver).id;
      const existingIds = this.getSelectorIDs();
      const finalResult = res.data.filter((x) => {
        const isInChildren =
                !existingIds.includes(x.id) && x.id !== self;

        return isInChildren;
      });

      return finalResult as Driver[];
    }))));

  public formatter = (driver: Driver): string => {
    const nameParts = {
      id: driver.id,
      first_name: driver.first_name ?? null,
      last_name: driver.last_name ?? null,
      organization_identifier: driver.organization_identifier,
      middle_name: driver.middle_name ?? null,
      prefix: driver.prefix ?? null,
      suffix: driver.suffix ?? null,
    };
    return this.displayNamePipe.transform(nameParts);
  };

  public onBlur(): void {
    if (!(this.fg?.value as Driver)?.id) {
      this.driverModel = undefined;
    }
    this.fg?.controls.id.markAsTouched();
    this.fg?.controls.id.updateValueAndValidity({ onlySelf: true });
  }

  private getSelectorIDs(): string[] {
    return [this.fg?.controls.id.value];
  }

}
